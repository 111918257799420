import axios from "@/axios";
export const auth = {
  user: null,
  token: null
};

export const authGetters = {
  getAuth: state => {
    return state.auth;
  },
  getAuthToken: state => {
    return state.auth.token;
  },
  getUser: state => {
    return state.auth.user;
  }
};

export const authMutations = {
  changeAuthCode(state, value) {
    state.auth = value;
  }
};

export const authActions = {
  act_login: async ({ commit }, payload) => {
    try {
      let response = await axios.post("login", payload);
      commit("changeAuthCode", response.data.data);
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, message: error.response.data.message };
    }
  },
  act_logoutAuth: async ({ commit, getters }) => {
    try {
        let response = await axios.post(
            "logout",
            {},
            {
                headers: {
                    Authorization: "Bearer " + getters.getAuthToken,
                },
            }
        );
        commit("changeAuthCode", {
          user: null,
          token: null
        });
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
  },
};
