import { authActions } from "./auth";
import { settingActions } from "./appSetting";
import { companyActions } from "./compnay";
export default {
  act_changLanguage: ({ commit }, payload) => {
    commit("changLanguage", payload);
  },
  ...authActions,
  ...settingActions,
  ...companyActions,
};
