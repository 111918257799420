export const setting = {
  drawer: true,
};
export const settingGetters = {
  getSetting: state => {
    return state.setting;
  },
  getDrawer: state => {
    return state.setting.drawer;
  },
};

export const settingMutations = {
  changeDrawer(state, value) {
    state.setting.drawer = !state.setting.drawer;
  },

};

export const settingActions = {
  act_drawer: ({ commit }, payload) => {
    commit("changeDrawer");
  },
};
