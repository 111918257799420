import axios from "@/axios";
export const company = [];

export const companyGetters = {
  getCompany: state => {
    return state.company;
  },
  getCompayById: (state) => (id) => {
    return state.company.find(p => p.id == id);
  },
};

export const companyMutations = {
  changeCompany(state, value) {
    state.company = value;
  },
  
};

export const companyActions = {
  act_loadCompany: async ({ commit,getters },payload) => {
    try {
      const response = await axios.get("company/query-list",{headers: {Authorization: "Bearer " + getters.getAuthToken}});
      commit("changeCompany", response.data.data);
      return { success: true, data: response.data };
    }  catch (error) {
      return { success: false, message: error.response.data.message };
    }
  },
  act_getCompanyDetail: async ({ commit,getters },payload) => {
    try {
      const response = await axios.get("company/get-detail/"+payload.id,{headers: {Authorization: "Bearer " + getters.getAuthToken}});
      let data = [];
      data.push(response.data.data);
      commit("changeCompany", data);
      return { success: true, data: response.data };
    }  catch (error) {
      return { success: false, message: error.response.data.message };
    }
  }
};
