import axios from "axios";
import store from '../stores';
import router from "../router/router";

const instance = axios.create({
    baseURL: 'http://127.0.0.1:8000/edisclosure/v1'
    //baseURL: 'https://trainingapi.serc.gov.kh/sna-v1'//server
}); 
instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        //store.dispatch('act_logoutAuth');
        store.commit("changeAuthCode", {
          user: null,
          token: null
        });
        router.push({ name: "Login" });
    }
    return Promise.reject(error);
  });
export default instance;