//import Vue from 'vue'
import { createStore, createLogger } from "vuex";
//import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

import state from "./states";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

// Create a new store instance.
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: process.env.NODE_ENV !== 'production'
  ? [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
      paths: ['lan','auth','setting']
    }),
    createLogger()
  ]
  : [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
      paths: ['lan','auth','setting']
    }),
]
});
