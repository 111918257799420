import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router/router"
// import { loadFonts } from './plugins/webfontloader'
//import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";


//vuex store
import store from "./stores/index";

///VueI18n
import { createI18n } from "vue-i18n";
import en from "./translations/en.json";
import kh from "./translations/kh.json";
const messages = {
  en: en,
  kh: kh
};
const i18n = createI18n({
  locale: "kh", // set locale
  fallbackLocale: "en", // set fallback locale
  messages // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

///VueLoading
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

///vue-toast-notification
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import '@/assets/css/style.css';

//loadFonts()

const app = createApp(App);
// Disable Vue.js production tip
app.config.productionTip = false;
// Disable all Vue.js warnings and errors
app.config.silent = true;
app.use(vuetify)
  .use(router)
  .use(store)
  .use(i18n)
  .use(LoadingPlugin)
  .use(ToastPlugin)
  .mount('#app');

//Add this in your code after the Vue instance is created
console.warn = (message) => {
  if (!message.includes('[Vuetify UPGRADE]')) {
    console.error(message);
  }
};
