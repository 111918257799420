import { authMutations } from "./auth";
import { settingMutations } from "./appSetting";
import { companyMutations } from "./compnay";
export default {
  changLanguage(state) {
    state.lan = state.lan == "kh" ? "en" : "kh";
  },
  ...authMutations,
  ...settingMutations,
  ...companyMutations
};
