const routes = [
  {
    path:"/",
    redirect: { path: "/" },
    component: () => import("@/layouts/ronvireak/Layout.vue"),
    children: [
      // {
      //   path:"/maintenance",
      //   name:"maintenance",
      //   component:()=>import("@/views/ronvireak/maintenance/Maintenance.vue"),
      //   meta:{
      //     title:"maintenance"
      //   }
      // },
      {
        path: "",
        name: "ronvireak-dashboard",
        component: () => import("@/views/ronvireak/dashboard/Dashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
    ],
  },
];

export default routes;
