import { auth } from "./auth";
import { setting } from "./appSetting";
import { company } from "./compnay";

export default {
  lan: "kh",
  auth,
  setting,
  company,
};
