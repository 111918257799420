import { createRouter, createWebHistory } from "vue-router";
import store from "@/stores";
import ronvireakRouter from "@/router/ronvireakRouter";

const routes = [
  {
    path: "/",
    redirect: "", // Redirect root path to /ronvireak
  },
  ...ronvireakRouter,
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "Login") {
    if (store.getters.getAuthToken) {
      if (store.getters.getUser.role == 1) {
        next({ name: "serc-dashboard" });
      } else if (store.getters.getUser.role == 2) {
        next({ name: "csx-dashboard" });
      } else if (store.getters.getUser.role == 3) {
        next({ name: "listedcompany-dashboard" });
      } else {
        next();
      }
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //console.log("requiresAuth");
    if (!store.getters.getAuthToken) {
      //store.dispatch("act_logoutAuth");
      next({ name: "Login" });
    } else {
      if (to.name == "Login") {
        if (store.getters.getUser.role == 1) {
          next({ name: "serc-dashboard" });
        } else if (store.getters.getUser.role == 2) {
          next({ name: "csx-dashboard" });
        } else if (store.getters.getUser.role == 3) {
          next({ name: "listedcompany-dashboard" });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
  next();
});

export default router;
